import {useTheme} from "@material-ui/styles";
import React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import NewHomeModal from '../modals/newHomeModal'
import CanvasHouseViewer from '../canvasHouseViewer/canvasHouseViewer'
import ResponsiveModal from '../ui/ResponsiveModal'
import { NewJobContainer, NewOptionsDrawer } from '../../pages/jobs/new'
import JobCreator from '../jobCreator/jobCreator'
import { getDecodedToken } from '../../utils/client'
import Tour from 'reactour'

export const getExampleRenoworksId = () => {
	return process.env.GATSBY_EXAMPLE_RENOWORKS_ID || 'a44780ba-b6be-4ba5-9850-b5028cc40fc3' // TODO: Remove hardcoded ID
}

export const getExampleLegacyHomeSpecId = () => {
	return process.env.GATSBY_EXAMPLE_LEGACY_HOME_SPEC_ID || 38 // TODO: Remove hardcoded ID
}


const exampleSpec = require('../../shared/examplePropertySpec.json')
export const getExamplePropertySpec = () => {
	return exampleSpec
}

const WelcomeMessage = () => (
	<div>
		<Typography variant={'h6'}>Welcome to Fairmarket</Typography>
		<Typography>We'll guide you through the necessary steps to get you started!</Typography>
	</div>
)

const CreatePropertyMessage = () => (
	<div>
		<Typography variant={'h6'}>Create your first property</Typography>
		<Typography style={{ marginBottom: '20px'}}>In order to create jobs, please create your first property!</Typography>
		<NewHomeModal onClose={() => null} noTitles={true} />
	</div>
)

const NoPropertiesYet = () => {
	const theme = useTheme();
	const [modalOpen, setModalOpen] = React.useState(false)

	const onPaymentEntered = (ev?: any) => {
		if (ev && ev.preventDefault) {
			ev.preventDefault()
		}
	}

	const hasToken = !!getDecodedToken()

	const steps = [
		{
			content: <WelcomeMessage />
		},
		{
			content: <CreatePropertyMessage />
		},
	]
	return (
		<>
			{typeof window !== 'undefined' && Tour && <Tour
				steps={steps}
				isOpen={true}
				showCloseButton={false}
			/>}
			<NewJobContainer>
				<CanvasHouseViewer
					style={{ display: 'flex' }}
					renoworksId={getExampleRenoworksId()}
					propertySpec={getExamplePropertySpec()}
					newViewer={false}
					viewOnly={false}
				>
				</CanvasHouseViewer>
				<NewOptionsDrawer
					anchor="right"
					variant="permanent"
					open
					theme={theme}
				>
					<JobCreator demoMode/>
				</NewOptionsDrawer>
			</NewJobContainer>
			<ResponsiveModal open={modalOpen} onClose={() => setModalOpen(false)} overflow={'inherit'}>
				<NewHomeModal onClose={onPaymentEntered}/>
			</ResponsiveModal>
		</>
	)
}

export default NoPropertiesYet;

export const StyledSidebarCard = styled(Card)`
  position: fixed;
	padding: 10px;
	padding-top: 6px;
	text-align: center;
	z-index: 1000;

	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: space-evenly;
	&& > p {
		margin-bottom: 8px;
	}

	@media(min-width: 701px) {
		width: 220px;
    left: 10px;
    z-index: 999;
    top: 55%;
    padding: 24px 12px;
	}

	@media(max-width: 700px) {
		right: 32px;
		left: 32px;
		top: 62px;
		padding: 16px;
		padding-top: 12px;
		> * {
			font-size: 16px;
			font-weight: 500;
		}
	}
`
